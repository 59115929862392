<template>
    <div class="submit-butter-bar">
        <div class="container">
            As of March 1st, HaloRuns is longer accepting submissions from twitch.tv for top submissions. Please <a href="https://help.twitch.tv/s/article/video-on-demand?language=en_US#export" rel="noopener" target="_blank">export/upload</a> videos to a more permanent hosting site such as YouTube. <br/> This action is being taken due to Twitch's past and present actions of deleting old videos with little notice.
        </div>
    </div>
</template>

<script lang="ts">
import { RunFeedEntry } from "@/api";
import { Component, Vue, Prop } from "vue-facing-decorator";

@Component({})
export default class SubmitButterBar extends Vue {
    
}
</script>

<style scoped lang="scss">
@use "@/assets/haloruns_vars.scss" as *;

.submit-butter-bar {
    background-color: rgb(25, 38, 94);
    margin-bottom: 15px;
    padding: 10px;
    justify-content: center;
    text-align: center;
}

</style>

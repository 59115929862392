import { Component, Prop, Watch, Vue } from "vue-facing-decorator";
import RunInputService, { RunInputContext, RunInputContextPartner } from "@/services/RunInputService";
import TimeService from "@/services/TimeService";
import UrlService from "@/services/UrlService";
import { RunSubmissionsService, Category, Game, RunnableSegment, RunSubmission, StaticContentService, RecordBoardEntry, UserCareer, ApiError, Run, Difficulty } from "@/api";
import { AppInsights } from '@/main';
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { State } from "@/store";
import { Store } from "vuex";
import { Router } from "vue-router";
import ConfirmArguments from "@/plugins/Confirm";
import { ToastInterface } from "vue-toastification";

interface SubmissionEntryValidationContext {}

export interface SubmitHost {
    game: Game | null;
    category: Category | null;
    difficulty: Difficulty | null;
}

export default class SubmitBase {

    $store: Store<State>;
    $router: Router;
    $loading: (show: boolean) => void;
    $confirm: (args?: ConfirmArguments | null) => Promise<boolean>;
    $emit: (event: string, ...args: any[]) => void;
    $toast: ToastInterface;
    $filters: Record<string, any>;
    
    host: SubmitHost;

    public submitKind: string | null = null;
    public career: UserCareer | null = null;
    public recordData: { [runnableSegmentId: string]: { [difficulty: string]: RecordBoardEntry[] } } | null = null;
    public submissions: RunInputContext[] = new Array();

    constructor(submitHost: SubmitHost, 
        store: Store<State>, 
        router: Router, 
        loading: (show: boolean) => void, 
        confirm: (args?: ConfirmArguments | null) => Promise<boolean>,
        emit: (event: string, ...args: any[]) => void,
        toast: ToastInterface,
        filters: Record<string, any>) 
    {
        this.host = submitHost;
        this.$store = store;
        this.$router = router;
        this.$loading = loading;
        this.$confirm = confirm;
        this.$emit = emit;
        this.$toast = toast;
        this.$filters = filters;
    }

    private errors: string[] = new Array();

    async mounted(submitKind: string){
        this.submitKind = submitKind;
        const userId = this.$store.state.auth.claims.userId;
        this.career = await StaticContentService.userCareer(userId);
        await this.initialize();
    }

    async initialize() {



        this.clean();
        this.errors = new Array();
        await this.fetchRecords();
        this.buildSubmissions();
    }

    async fetchRecords() {
        if(this.host.game == null || this.host.category == null) {
            this.recordData = null;
            return;
        }

        try {
            let recordBoard = await StaticContentService.recordBoard(this.host.game.Id!, this.host.category.Id!);
            let initial = {} as {[runnableSegmentId: string]: { [difficulty: string]: RecordBoardEntry[] }};
            this.recordData = recordBoard.Entries?.reduce ((a: any, c) => { a[c.RunnableSegmentId!] = c.RecordsByDifficulty; return a; }, initial);
        } catch {
            this.recordData = null;
        }
    }

    public buildSubmissions() {
        if(this.host.game == null || this.host.category == null || this.host.difficulty == null) {
            this.submissions = new Array();
            return;
        }

        let newSubmissions = new Array();
        
        var lookup = this.categoryLevels;

        if(lookup == null) 
            return;

        for(let level of this.host.category?.AllowedSegments ?? []) {
            var sub = this.createSubmissionForLevel(lookup[level]);

            if(sub) newSubmissions.push(sub);
        }

        this.submissions = newSubmissions;
    }

    private createSubmissionForLevel(level: RunnableSegment) {
        if(this.host.game == null || this.host.category == null || this.host.difficulty == null) {
            return null;
        }

        let partners = new Array() as RunInputContextPartner[];
        for(let i = 0; i < (this.host.category?.RequiredPartners ?? 0); i++) {
            partners.push({} as RunInputContextPartner);
        }

        let filterValues = {} as {[filterId: string]: string | undefined};

        for(let filter of this.host.category.Filters ?? []) {
            if(filter.DefaultValue) {
                filterValues[filter.Id!] = filter.DefaultValue.Id;
            }
        }

        let pb: Run | null = null;

        if(this.career?.RunsByCategory) {
            var runs = this.career.RunsByCategory[this.host.category!.Name!];

            if(runs != null && runs.length > 0) {
                var segmentRun = runs.find((r: any) => r.GameId == this.host.game!.Id 
                    && r.DifficultyId == this.host.difficulty?.Id
                    && r.RunnableSegmentId == level.Id);

                pb = segmentRun || null;
            }
        }

        
        return {
            gameId: this.host.game.Id,
            categoryId: this.host.category.Id,
            runnableSegmentId: level.Id,
            runnableSegmentName: level.Name,
            isIl: level.Id != null,
            difficulty: this.host.difficulty.Id,
            requiredPartners: this.host.category.RequiredPartners,
            allowedPartners: this.host.category.AllowedPartners,
            partners: partners,
            duration: null,
            evidenceLinks: [] as string[],
            filterValues: filterValues,
            validation: {} as SubmissionEntryValidationContext,
            durationModifyHook: s => {},
            pb: pb
        } as RunInputContext;
    }

    public createGlobalSubmissionContext() {
        if(this.host.game == null || this.host.category == null || this.host.difficulty == null) {
            return null;
        }

        let partners = new Array() as RunInputContextPartner[];
        for(let i = 0; i < (this.host.category?.RequiredPartners ?? 0); i++) {
            partners.push({} as RunInputContextPartner);
        }

        let filterValues = {} as {[filterId: string]: string | undefined};

        for(let filter of this.host.category.Filters ?? []) {
            if(filter.DefaultValue) {
                filterValues[filter.Id!] = filter.DefaultValue.Id;
            }
        }


        return {
            gameId: this.host.game.Id,
            categoryId: this.host.category.Id,
            difficulty: this.host.difficulty.Id,
            requiredPartners: this.host.category.RequiredPartners,
            allowedPartners: this.host.category.AllowedPartners,
            partners: partners,
            evidenceLinks: [] as string[],
            filterValues: filterValues,
            validation: {} as SubmissionEntryValidationContext
        } as RunInputContext;
    }

    get categoryLevels(): {[props: string]: RunnableSegment} {
        if(this.host.category == null) return {};

        var initial = {} as {[props: string]: RunnableSegment};

        return this.host.game?.RunnableSegments!
            .filter(l => this.host.category!.AllowedSegments!.indexOf(l.Id!) >= 0)
            .reduce ((a: any, c) => { a[c.Id!] = c; return a; }, initial);
    }

    async submit() {
        let subs = this.collectAndValidateSubmissions();

        if(subs == null || this.errors?.length > 0) {
            this.$confirm({
                title: "There are some issues with your run(s)",
                message: this.errors.join("\r\n"),
                cancelAlias: null,
                confirmAlias: "Ok"
            });
            this.errors = [];
            return;
        }

        let messages = subs.map(s => `${s.probablyRecord ? 'World Record for ' : 'Run for '}${s.runnableSegmentName} in ${TimeService.secondsToString(s.duration!)}`);

        let title = `Submitting for ${this.host.game?.Name} - ${this.host.category?.Name} (${this.host.difficulty?.Name}):`;

        let confirmed = await this.$confirm({
            title: title,
            message: messages.join("\r\n")
        })

        if(!confirmed) return;

        // Build result object that can be used on submit to pop warnings/confirmation text
        var submissionDtos = subs.map(this.getSubmissionDto, this);

        try {
            this.$loading(true);
            AppInsights.trackTrace({
                message: `Run submission sending`,
                properties: { submitAction: "submit", submitSource: this.submitKind },
                severityLevel: SeverityLevel.Information
            });
            let resp = await RunSubmissionsService.submitRuns(submissionDtos);
            this.$loading(false);
            this.clean();

            this.$toast.success("Submission complete!", { timeout: 3000, closeButton: false, hideProgressBar: false });
            setTimeout(() => { 
                this.$router.push('/');
            }, 3000);
        } catch (e: any) {
            AppInsights.trackException({
                exception: e,
                properties: { submitAction: "exception", submitSource: this.submitKind },
                severityLevel: SeverityLevel.Error
            });

            this.$loading(false);
            var err = e as ApiError;
            var resp = err.body as Record<string, { Action: "Created" | "Invalid", Message: string}>;

            let errors = new Array();
            let success = 0;

            for (const segmentId in resp) {
                var result = resp[segmentId];
                if(result.Action == "Created") {
                    this.removeSubmission(segmentId);
                    success++;
                } else {
                    errors.push(`Submission for ${this.categoryLevels[segmentId].Name} failed: ${result.Message}`);
                }
            }

            if(errors.length == 0 || errors.every(e => !e)) {
                errors.push("An unknown error occurred :(")
            }

            let confirm = await this.$confirm({
                message: errors.join("\r\n") + (success > 0 ? `\r\n${success} runs have been submitted successfully` : ""),
                confirmAlias: "Fix Submissions",
                cancelAlias: "Abandon"
            });

            if(!confirm) {
                this.clean();
                this.$router.push("/");
            }
        }
    }

    removeSubmission(segmentId: string) {
        let index = this.submissions.findIndex(s => s.runnableSegmentId == segmentId);
        this.submissions.splice(index, 1);
    }

    get liveSubmissionCount() {
        return this.submissions.filter(s => s.duration != null && s.duration > 0).length;
    }

    collectAndValidateSubmissions(): RunInputContext[] | null {
        this.errors = new Array();

        // verify we were able to load record/PB data
        if(this.recordData == null) {
            this.$toast.error("Unable to load current records, can't submit. Try again later");
            throw "Failed to fetch current Records, cannot submit";
        }

        // collect each submission candidate that has a time entered
        var liveSubmissions = this.submissions.filter(s => s.duration != null && s.duration > 0);

        let sufficientEvidence = true;
        let sufficientPartners = true;
        let sufficientPartnerInfo = true;
        let sufficientFilterInfo = true;
        for(let sub of liveSubmissions) {
            for(let i = 0; i < sub.partners.length + 1; i++) {
                let validated = this.validateUrl(sub, this.errors, sub.evidenceLinks[i]);
                sub.evidenceLinks[i] = validated;
            }

            if(sub.requiresEvidence) {
                for(let i = 0; i < sub.partners.length + 1; i++) {
                    if(!sub.evidenceLinks[i]) {
                        sufficientEvidence = false;
                        break;
                    }
                }
            }

            sufficientPartners = sub.partners.length >= sub.requiredPartners;

            if(sub.partners?.length) {
                for(let i = 0; i < sub.partners.length; i++) {
                    if(!sub.partners[i].id || !sub.partners[i].username) {
                        sufficientPartnerInfo = false;
                        break;
                    }
                }
            }

            for(let filt of this.host.category?.Filters || []) {
                if(filt.Id && filt.Required) {
                    if(!sub.filterValues[filt.Id]) {
                        sufficientFilterInfo = false;
                        break;
                    }
                }
            }
        }

        if(sufficientEvidence && sufficientPartners && sufficientPartnerInfo && sufficientFilterInfo) {
            return liveSubmissions;
        } else {
            // buld error message
            if(!sufficientEvidence) {
                this.errors.push("One or more runs are missing a video link");
            }

            if(!sufficientPartners) {
                this.errors.push("One or more runs are missing coop partner(s)");
            }

            if(!sufficientPartnerInfo) {
                this.errors.push("One or more runs are missing a video link for a coop partner");
            }

            if(!sufficientFilterInfo) {
                this.errors.push("One or more runs are missing a value for a required filter");
            }

            return null;
        }
    }

    public bannedHosts = ["twitch.tv"];

    validateUrl(sub: RunInputContext, errors: string[], url: string | null): string | null {
        if(url == null)
            return null;

        var normalized = UrlService.tryGetValidUrl(url);

        if(normalized == null)
            return null;

        // Only enforcing banned domain check when requring evidence
        if(!sub.requiresEvidence)
            return normalized;

        var host = new URL(normalized).host.toLowerCase();

        if(this.bannedHosts.filter(h => host.indexOf(h) > 0).length > 0) {
            errors.push(`Host ${host} is not accepted as a valid video evidence link`);
        }

        return normalized;
    }

    getSubmissionDto(submissionEntry: RunInputContext) : RunSubmission {
        const userId = this.$store.state.auth.claims.userId;
        const participants = submissionEntry.partners.map((p, i) => { return { UserId: p.id, Username: p.username, EvidenceLink: submissionEntry.evidenceLinks[i+1]};});
        participants.unshift({UserId: userId, Username: this.$store.state.auth.claims.name, EvidenceLink: submissionEntry.evidenceLinks[0]});

        return {
            UserId: userId,
            GameId: submissionEntry.gameId,
            CategoryId: submissionEntry.categoryId,
            Duration: submissionEntry.duration!,
            DifficultyId: submissionEntry.difficulty,
            RunnableSegmentId: submissionEntry.runnableSegmentId,
            Participants: participants,
            FilterValues: submissionEntry.filterValues
        } as RunSubmission;
    }

    async dirty(submission: RunInputContext) {
        if(!submission.editingDuration && submission?.duration) {
            let pb = submission.pb;

            if(pb && submission.duration >= TimeService.stringToSeconds(pb.Duration)) {
                let edit = await this.$confirm({ 
                    message: `This time is not better than your PB of ${this.$filters.durationDisplay(pb.Duration)}. \r\n Do you want to edit your time instead? \r\n (you'll lose any progress here if so)`, 
                    cancelAlias: "Stay here",
                    confirmAlias: "Yes, go edit my times" });

                submission.duration = null;
                submission.durationModifyHook(submission);

                if(edit) {
                    this.$router.push({name: "Edit Run", params: { runId: pb.Id!, partitionKey: pb.PartitionKey!}})
                }
            }
        }

        this.$emit("update:modelValue", { inProgress: true });
    }

    clean() {
        this.$emit("update:modelValue", { inProgress: false });
    }
}